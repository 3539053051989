import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel, Answer } from '@/models/Report';
import { Submission, statusColorMap, SubmissionStatus, statusLabelMap } from '@/models/Submission';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { GeneralKeys, GeneralKey } from '@/support/GeneralKeys';
import { Rpc } from '@/models/Rpc';

@Component<MSR>({})
export default class MSR extends Vue {
  public $pageTitle = 'MSR';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  protected isLoading = true;

  protected enableEditing = false;

  protected isAddingSubmission = false;

  protected isCancelingSubmission = false;

  protected submissionDamages: Submission[] = [];

  protected generalKeys: GeneralKey = GeneralKeys;

  protected cancelSubmissionPayload = {
    reason: '',
    notify: false,
  }

  protected selectedSubmissionDamage: Submission | null = null;

  protected statusColorMap: SubmissionStatus = statusColorMap;

  protected statusLabelMap: SubmissionStatus = statusLabelMap;

  protected async mounted() {
    this.isLoading = true;
    await this.loadSubmissions();
    this.setGeneralInfo();

    this.isLoading = false;
  }

  protected setGeneralInfo() {
    for (const key in this.generalKeys) {
      this.generalKeys[key].value = '';
    }

    const keys = Object.keys(this.generalKeys);
    if (! this.report || ! this.report.answers) {
      return;
    }

    this.report.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  protected async loadSubmissions() {
    this.submissionDamages = await new Submission()
      .include(['applicant', 'statistics'])
      .filter({
        report: this.report.uuid,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected reload() {
    this.$emit('reload');
  }

  protected openCancelSubmission(submissionDamage: Submission) {
    this.selectedSubmissionDamage = submissionDamage;
    this.isCancelingSubmission = true;
  }

  protected async cancelSubmission() {
    if (! this.selectedSubmissionDamage) { return; }
    this.isLoading = false;

    const payload: {[key: string]: any} = {
      signature: 'msr:cancel-submission',
      body: {
        id: this.selectedSubmissionDamage.id,
        cancel_reason: this.cancelSubmissionPayload.reason,
        notify_applicant: this.cancelSubmissionPayload.notify,
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.selectedSubmissionDamage = null;
        this.isCancelingSubmission = false;
        this.isLoading = false;
      }).catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
