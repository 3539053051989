var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel" },
    [
      _vm.isLoading
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "300px"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              {
                staticClass: "profileContainer",
                attrs: { wrap: "", "align-center": "" }
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs3: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            staticClass: "label",
                            attrs: { xs12: "", "pb-0": "" }
                          },
                          [_vm._v("Toegangscode:")]
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass:
                              "value disabled font-weight-bold headline",
                            attrs: { xs12: "", "pt-0": "" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.submissionDamages[0]
                                    ? _vm.submissionDamages[0].applicant.code
                                    : ""
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs3: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            staticClass: "label",
                            attrs: { xs12: "", "pb-0": "" }
                          },
                          [_vm._v("Postcode:")]
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass: "value disabled",
                            attrs: { xs12: "", "pt-0": "" }
                          },
                          [_vm._v(_vm._s(_vm.report.address.postcode))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs3: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            staticClass: "label",
                            attrs: { xs12: "", "pb-0": "" }
                          },
                          [_vm._v("Telefoonnummer:")]
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass: "value disabled",
                            attrs: { xs12: "", "pt-0": "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.report.applicant
                                  ? _vm.report.applicant.phone
                                  : ""
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "fixedActionsContainer" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table--fixedActions item__list item__list--table"
                },
                [
                  _vm._m(0),
                  _vm._l(_vm.submissionDamages, function(submissionDamage) {
                    return _c(
                      "tr",
                      {
                        key: submissionDamage.id + "-cell",
                        staticClass: "list__item list__item--static"
                      },
                      [
                        _c("td", [
                          _vm._v(" " + _vm._s(submissionDamage.code) + " ")
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormatTime")(
                                  submissionDamage.submitted_at
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            {
                              class:
                                "statusPill statusBackgroundColor--" +
                                _vm.statusColorMap[submissionDamage.status]
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.statusLabelMap[submissionDamage.status]
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("td", { staticClass: "text-xs-right" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                submissionDamage.statistics.total_damages
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "text-xs-right" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                submissionDamage.statistics.total_pictures
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "text-xs-right" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                submissionDamage.statistics.total_attachments
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                submissionDamage.applicant
                                  ? submissionDamage.applicant.name
                                  : "-"
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                submissionDamage.applicant
                                  ? submissionDamage.applicant.email
                                  : "-"
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "actions" }, [
                          _c(
                            "span",
                            { staticClass: "item__icon item__icon--action" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to:
                                      "/mijn-schaderegelen/" +
                                      submissionDamage.id
                                  }
                                },
                                [_c("v-icon", [_vm._v("remove_red_eye")])],
                                1
                              )
                            ],
                            1
                          ),
                          !submissionDamage.isSubmitted
                            ? _c(
                                "span",
                                {
                                  staticClass: "item__icon item__icon--action",
                                  on: {
                                    click: function($event) {
                                      return _vm.openCancelSubmission(
                                        submissionDamage
                                      )
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ]),
            _vm.isCancelingSubmission
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "600px" },
                    model: {
                      value: _vm.isCancelingSubmission,
                      callback: function($$v) {
                        _vm.isCancelingSubmission = $$v
                      },
                      expression: "isCancelingSubmission"
                    }
                  },
                  [
                    _c(
                      "DefaultDialog",
                      {
                        on: {
                          close: function($event) {
                            _vm.isCancelingSubmission = false
                          }
                        }
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("h2", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.selectedSubmissionDamage
                                    .isAdditionalRequest
                                    ? "Aanvullende aanvraag"
                                    : "Zelfopname"
                                ) +
                                " annuleren "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " Weet je zeker dat je deze " +
                                _vm._s(
                                  _vm.selectedSubmissionDamage
                                    .isAdditionalRequest
                                    ? "aanvullende aanvraag"
                                    : "zelfopname"
                                ) +
                                " wilt annuleren? "
                            )
                          ])
                        ]),
                        _c(
                          "template",
                          { slot: "content" },
                          [
                            _vm.isLoading
                              ? _c("LoaderCard", {
                                  attrs: { flat: "", type: "spinner--center" }
                                })
                              : _vm._e(),
                            !_vm.isLoading
                              ? _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "justify-space-between": "",
                                      "align-baseline": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            name: "cancel_reason",
                                            label: "Toelichting"
                                          },
                                          model: {
                                            value:
                                              _vm.cancelSubmissionPayload
                                                .reason,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.cancelSubmissionPayload,
                                                "reason",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "cancelSubmissionPayload.reason"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            label:
                                              "Notificatie naar bewoner sturen",
                                            "hide-details": "",
                                            name: "notify_applicant",
                                            color: "#a09b1b"
                                          },
                                          model: {
                                            value:
                                              _vm.cancelSubmissionPayload
                                                .notify,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.cancelSubmissionPayload,
                                                "notify",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "cancelSubmissionPayload.notify"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "actions" },
                          [
                            !_vm.isLoading
                              ? _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "justify-end": "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { shrink: "" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fadedText link mr-3",
                                            on: {
                                              click: function($event) {
                                                _vm.isCancelingSubmission = false
                                              }
                                            }
                                          },
                                          [_vm._v("Sluiten")]
                                        ),
                                        _c(
                                          "MiButton",
                                          {
                                            attrs: {
                                              color: "primary",
                                              small: "true"
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.cancelSubmission(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Ja, annuleren")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "tr",
      {
        staticClass: "label",
        attrs: { wrap: "", row: "", "align-center": "" }
      },
      [
        _c("td", [_vm._v("Code (oud)")]),
        _c("td", [_vm._v("Ingeleverd op")]),
        _c("td", [_vm._v("Status")]),
        _c("td", { staticClass: "text-xs-right" }, [_vm._v("# schades")]),
        _c("td", { staticClass: "text-xs-right" }, [_vm._v("# schadefoto's")]),
        _c("td", { staticClass: "text-xs-right" }, [_vm._v("# bijlagen")]),
        _c("td", [_vm._v("Naam")]),
        _c("td", [_vm._v("Email")]),
        _c("td", { staticClass: "actions" }, [_vm._v("Acties")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }